.NoticeTextAreaWrapper {
  height: 100%;
  width: 68%;
  background-color: white;
  border-radius: 12px;
  overflow-y: scroll;
}
.RecipientWrapper {
  height: 100%;
  width: 30%;
  background-color: white;
  border-radius: 12px;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* restyling the tool bar */
.ql-toolbar {
  width: fit-content !important;
  border: none !important;
  padding: 10px 26px !important;
  position: sticky !important;
  top: 90px !important;
  background-color: white !important;
  z-index: 1 !important;
}

.ql-editor {
  border: none !important;
  padding: 20px 35px !important;
  height: 200px;
  font-size: 16px;
}

.ql-container {
  border: none !important;
}

.ql-editor.ql-blank::before {
  left: 30px !important;
}

.TextEditor {
  position: relative;
}

.OurToolBar {
  position: absolute;
  right: 30px;
  top: 0;
  width: fit-content;
  height: 44px;
}

/* right side select industry container */
.Recipients_searchbar {
  width: 100%;
  height: fit-content;
  padding: 20px 20px 16px 20px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  margin-bottom: 10px;
}
.Industry_Selection_tile {
  width: 100%;
  height: fit-content;
  padding: 10px 20px;

  font-size: 15px;
  font-weight: 500;

  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.Industry_tile_left_container {
  width: 85%;
}

.InputLabel {
  position: relative;
}
.InputLabel:hover .FileHoverContainer {
  display: block;
}
.FileHoverContainer {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 2;
  display: none;
  width: fit-content;
  height: fit-content;
  padding: 20px;
  background-color: white;
  transition: 0.3s;
  border-radius: 10px;
  text-wrap: nowrap;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.116);
}
