@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

:root {
  --Drawer_Blue: #273647;
  --Drawer_Item_Hover: #233140;
  --Drawer_Active_blue: #1f2b39;
  --Primary_Blue: #338feb;
  --Tertiary_Blue: #f5faff;
  --Line_Stroke_Color: #dfe1e6;

  --Primary_text_color: #404040;
  --Secondary_text_color: #999999;

  --zIndexModals: 100;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: lightgrey;
}

/* hiding arrows from number input globally */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* NO internet component styling */
.noInternetWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.simpleParagraph {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8d8d8d;
}

/* a class for centering a div absolutely. User in tables when there is an error or anything where we want to show a dialog in the center of the container.  */
.absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link {
  text-decoration: underline 1px solid black;
  cursor: pointer;
}

.restrictForMobileDevices {
  display: none;
}

@media (max-width: 768px) {
  .App {
    display: none;
  }
  .restrictForMobileDevices {
    display: block;
    color: black;
  }
}
