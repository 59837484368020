/* modal Basic Styling */
.Modal_Black_bg {
  width: 100vw;
  height: 100vh;
  background-color: #00000052;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--zIndexModals);
  animation: fade-up 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.Modal_base {
  width: 600px;
  min-width: 500px;
  max-width: 800px;
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.05);
  background: var(--neutral-light-n-0, #fff);
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
    0px 3px 5px 0px rgba(9, 30, 66, 0.2);
  animation: fade-up 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.Modal_heading {
  background: #e5f1ff;
  padding: 10px 20px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: var(--Drawer_Blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
}

.Modal_content {
  padding: 20px;
}

.model_add {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.add_btns {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
