.ProceedButton {
  width: 90%;
  height: 40px;
  background-color: var(--Primary_Blue);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 16px;
  border: none;
  color: #ffff;
  border-radius: 8px;
  margin: 10px 10px 20px 10px;
  cursor: pointer;
  transition: 0.2s;
  user-select: none;
}
.ProceedButton:hover {
  background: #267cd1;
}
.disabledButton {
  background-color: lightgrey;
  cursor: not-allowed;
}
.disabledButton:hover {
  background-color: lightgrey;
}

/* Loader css */
.loader {
  border: 3px solid white;
  border-radius: 50%;
  border-top: 6px solid transparent;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* success illustration */
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 30px auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

#ErrorTicker {
  width: fit-content;
  height: fit-content;
  padding: 10px 30px;

  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: var(--zIndexModals);

  font-size: 14px;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 6px 6px 0px 0px;
  transition: 0.3s;
}
