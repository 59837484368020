/* this page header will use in every component and change accordingly, but base of the header will remain same. */
.PAGE_HEADER {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #42526e;
  font-feature-settings: "clig" off, "liga" off;

  /* Title 1 / Bold */
  /* font-family: Barlow; */
  /* font-size: 28px; */
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 114.286% */
  letter-spacing: 0.5px;
}

/* wrpper for div which will contain all industry list */
.Industries_Body_Container {
  width: 100%;
  background-color: white;
  border-radius: 12px;
  height: calc(100vh - 100px);
  overflow-x: hidden;
  position: relative;
}

/* this tool wrapper may be included in every page. it will contain all tools related to content of the page */
.TOOLS_WRAPPER {
  width: 100%;
  padding: 20px 30px 20px 30px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.089);
  position: sticky;
  top: 0;
}

.INDUSTRY_TABLE_CONTAINER {
  width: 100%;
}

.bulk_container { 
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 970px;
}
