.Industry_Detail_Container {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  margin: 14px 0px;
  padding: 20px;
}
.Industry_Basic_detail_Wrapper {
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Industry_Detail_Container h3 {
  font-weight: 600;
  font-size: 18px;
}
