/* Container */
.IndustryRegFormContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Logo */
.IndustryRegFormLogo {
  height: 61.33px;
  width: 186.64px;
  margin-top: 15px;
}

/* Header */
.IndustryRegFormHeader {
  display: flex;
  justify-content: center;
  height: 80.78px;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #cbcbcb;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.121);
}

/* HeaderDetails */
.HeaderDetails {
  white-space: pre-wrap;
  color: rgba(0, 0, 0, 1);
  font-family: Inter;
  font-weight: 600;
  font-size: 18px;
  margin: 20px 0px;
}

/* Line1 */
.Line1 {
  border: 2px solid rgba(51, 143, 235, 1);
  width: 645.58px;
  margin: auto;
}

/* Frame */
.Frame {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #cbcbcb;
}

/* Row */
.Row {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

/* RowB */
.RowB {
  margin: 20px 20px 0px 0px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}

/* Label */
.Label {
  color: rgba(95, 95, 97, 1);
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
}

/* Input */
.Input {
  padding: 8px 16px;
  width: 320px;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px 10px 10px 10px;
}

/* Button */
.Button,
.ButtonG {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  width: 75px;
  height: 44px;
  border-radius: 8px;
  margin: 40px 10px 20px 10px;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: 1px solid transparent;
  z-index: 0;
}

.Button {
  background: #338feb;
  color: white;
}

.ButtonG {
  background: rgba(0, 0, 0, 0.05);
}

/* Dropdown.css */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  position: relative;
  width: 320px;
  font-size: 16px;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 16px;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 10px 10px 0px 10px;
  color: rgba(0, 0, 0, 1);
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
}

.dropdown-content {
  display: none;
  position: absolute;
  width: 320px;
  left: 0;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
