/* searchbar css */
.search_bar_wrapper {
  width: fit-content;
  height: fit-content;
  position: relative;
  cursor: pointer;
}
.search_bar {
  height: 40px;
  border-radius: 8px;
  outline: none;
  border: 2px solid var(--Line_Stroke_Color);
  padding-left: 40px;
  position: relative;
}
.search_icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

/* universal page tertiary blue header */
.UNIVERSAL_PAGE_HEADER {
  width: 100%;
  height: 64px;
  padding: 10px;
  display: flex;
  background-color: var(--Tertiary_Blue);
  justify-content: space-between;
  align-items: center;
  overflow: visible;
}

.SCROLLABLE_PAGE {
  width: 100%;
  height: calc(100vh - 170px);
  overflow-x: auto;
  overflow-y: scroll;
}
.SCROLLABLE_PAGE::-webkit-scrollbar {
  display: none;
}

/* styling for circular loader */
.CircularLoaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.svgLoader {
  width: 2.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.circLoader {
  fill: none;
  stroke: var(--Primary_Blue);
  stroke-width: 3;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

/* confirmation popup stylings */

.Confirmation_Popup_Wrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.336);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--zIndexModals);
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
}
.Confirmation_popup_base {
  width: 350px;
  height: fit-content;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  animation: fade-up 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}

/* SomethingWentWrong */
.SomethingWentWrong {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* side drawer styling */
.SideDrawerWrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.336);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: var(--zIndexModals);
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.2s;
}

.SideDrawerBase {
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  width: fit-content;
  min-width: 500px;
  height: 100vh;
  background-color: white;
  box-shadow: -4px 0px 14px rgba(0, 0, 0, 0.096);
  overflow-y: scroll;
}

/* universal loader css hre */
.UniversalLoaderWrapper {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.336);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--zIndexModals);
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.2s;
}
.UniversalLoader {
  display: block;
  --height-of-loader: 4px;
  --loader-color: #0071e2;
  width: 130px;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgb(255, 255, 255);
  position: relative;
}

.UniversalLoader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 1s ease-in-out infinite;
}

@keyframes moving {
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}

/* //Modal stylings herer */
.ModalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 70%; */
  min-width: 400px;
  height: fit-content;
  max-height: 70%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
  box-shadow: 14;
  border-radius: 12px;
  z-index: var(--zIndexModals);
  /* transition: 0.1s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
}
.ModalBox::-webkit-scrollbar {
  display: none;
}
