.Item_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Item_Category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Item_unit {
  display: flex;
  width: 265px;
}

.Item_addbtn {
  width: 100%;
  display: flex;
  justify-content: end;
}
