.search_form_team {
  display: flex;
  flex-direction: column;
}

#search_form_team_title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #273647;
}

.search_form_team p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #747474;
}

.Modal_content_edit {
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.morePermissionsParent {
  position: relative;
  width: fit-content;
  height: fit-content;
}
.morePermissions {
  position: absolute;
  top:100%;
  right: 0;
  z-index: 10;
  display: none;

  width: fit-content;
  height: fit-content;
  padding: 14px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.178);
}

