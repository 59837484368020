.DepartmentAccessContainer {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 0px;
}
.UserAccess {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.AddUserAccess {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
