.Navigation_Wrapper {
  width: 100%;
  /* height: 64px; */
  border-bottom: 1px solid var(--Line_Stroke_Color);
  display: flex;
  justify-content: flex-start;
  /* padding-left: 20px; */
}
.Navlink_Box {
  width: fit-content;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding: 3px 16px 0px 16px;
  cursor: pointer;
  border-bottom: 3px solid transparent;
  user-select: none;
}
.Navlink_Box:hover {
  background-color: var(--Tertiary_Blue);
}
.Navlink_Box img {
  margin-right: 10px;
}
.Navlink_active {
  font-weight: 600;
  border-bottom: 3px solid var(--Primary_Blue);
}
.New_Notice {
  width: 20px;
  height: 20px;
  background-color: var(--Primary_Blue);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  border-radius: 100px;
  color: white;
  margin-left: 8px;
}
