.Drawer_wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--Drawer_Blue);
  color: white;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Link_Container_Box {
  width: 100%;
  height: 56px;
  padding: 14px 20px;
  font-weight: 400;
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
  transition: 0.1s;
  user-select: none;
}
.Link_Container_Box span {
  width: 12px;
  text-decoration: none;
}
.Link_Container_Box:hover {
  background-color: var(--Drawer_Item_Hover);
}
.active {
  border-left: 5px solid var(--Primary_Blue);
  font-weight: 600;
  background-color: var(--Drawer_Active_blue);
  transition: 0.1s;
  transition-timing-function: cubic-bezier(0.05, 0.73, 0.65, 0.99);
}
.Link_Container_Dropdown {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
  user-select: none;
}
.Link_Container_Dropdown:hover {
  background-color: var(--Drawer_Item_Hover);
}
.Dropdown_Icon {
  position: absolute;
  right: 18px;
  top: 16px;
  transition: 0.2s;
  z-index: 10;
}

.Drawer_User_Details {
  width: 100%;
  height: fit-content;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Drawer_User_Details .drawer_name {
  font-weight: 500;
  font-size: 16px;
}
.Drawer_User_Details .drawer_post {
  font-weight: 400;
  font-size: 12px;
}
