.Main_Layout_Wrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
}
.Drawer_Container {
  width: 260px;
  min-width: 260px;
  /* max-width: 300px; */
  height: 100vh;
  background: var(--Drawer_Blue);
}
.Address_Bar_Container {
  width: calc(100vw - 260px);
  height: 40px;
  background-color: white;
  border-bottom: 1px solid #cbcbcb;
  display: flex;
  align-items: center;
  padding-left: 20px; /* this padding must be equal to the content body left padding due to alignment reasons */
  font-size: 14px;
  color: var(--Primary_text_color);
}
.Content_Body {
  width: calc(100vw - 260px);
  height: calc(100vh - 40px);
  background-color: #e5f1ff;
  padding: 0px 20px 0px 20px;
  overflow: hidden;
}
