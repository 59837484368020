/* Container */
.UserSelectionContainer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: #FFFFFF;
}

/* RightSubContainer */
.RightSubContainer {
    background-image: url("https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Others/IndustryIMG_s0VhRQ73F.png?updatedAt=1681285124726");
    width: 65%;
    height: 100%;
    margin-left: auto;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 100px;
}


/* LeftSubContainer */
.LeftSubContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 40px;
    height: 100vh;
    width: 35%;
    text-align: left;
}

/* Title */
.Title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #404040;
    text-align: left;
    margin: 0;
    padding: 0;
}

/* Msg */
.Msg {
    /* margin-top: 44px; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    text-align: left;
}

/* DivTop */
.DivTop {
    display: flex;
    /* align-items: center; */
    /* text-align: center; */
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 12px;
}

/* DivBottom */
.DivBottom {
    width: 100%;
}

/* Heading */
.Heading {
    width: 80%;
    position: relative;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    margin-right: 13%;
    text-transform: uppercase;
    color: #FFFFFF;
}

/* Content */
.Content {
    width: 70%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    backdrop-filter: blur(2px) 
}

/* MainButton */
.MainButton {
    width: 100%;
    margin-top: 10px;
    height: 70px;
    border-radius: 12px;
    border: none;
    /* border: 2px solid lightgrey; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    text-transform: uppercase;
    font-weight: bold;
    color: grey;
}

.MainButton:hover{
    background-color: rgb(219, 219, 219);
}

/* WhiteRectangle */
.WhiteRectangle {
    position: fixed;
    width: 51%;
    height: 53%;
    right: 0;
    bottom: 0;
    background: #FFFFFF;
    border-radius: 31px 0px 0px 0px;
}

/* DecorationImage */
.DecorationImage {
    width: 40px;
    margin-left: 0px 10px;
}


.SUPER_ADMIN_LOGIN_BUTTON{
    position: absolute;
    top: 20px;
    right: 20px;
}