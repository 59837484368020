.Grid1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: url("https://ik.imagekit.io/hgfugmm0dbi/OCEMS/Others/IndustryIMG_s0VhRQ73F.png?updatedAt=1681285124726") no-repeat  ;
  background-size: 100%;
}

@keyframes fade-up {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.Login {
  width: 400px;
  max-width: 600px;
  height: fit-content;
  background: #ffffff;
  border: 1px solid #cbcbcb;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.199);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fade-up 1s cubic-bezier(0.19, 1, 0.22, 1);
}
/* for fading left to right while rendering' */
.anim {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  animation-name: op;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
@keyframes op {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.Logologin {
  width: 200px;
  margin: 20px 0px 10px 0px;
}

.Message {
  width: 90%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #a1a0a3;
  /* margin-bottom: 10px; */
}

.UserName {
  padding: 0px 16px;
  width: 90%;
  height: 40px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  color: #000000;
  margin-top: 15px;
  transition: 0.5s;
}

.SignupRoute {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #a1a0a3;
  margin: 0px 0px 24px 0px;
}

.Error {
  width: 90%;
  font-size: 10px;
  color: #ff6161;
  margin-top: 10px;
  font-weight: 600;
}

/* styling for otp inputs */
.OTPWrapper {
  /* border: 2px solid red; */
}
.OTPWrapper input {
  width: 50px !important;
  height: 50px !important;
  user-select: none;
  font-weight: 600;
  color: var(--Primary_text_color);
  border-radius: 10px;
  border: 1px solid grey;
}
.OTPWrapper input:focus {
  border: 2px solid var(--Primary_Blue);
}

.ChangeFontRegularMUI{
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
}


/* Already_LoggedIn_User */
.Already_LoggedIn_User{
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 0px;
}