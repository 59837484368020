.container {
  height: 100vh;
}

.container__title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 18px;
}

.container__title p {
  font-size: 28px;
  font-weight: 700;

  font-family: barlow, serif;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 18px;
  font-size: 14px;

  color: #42526e;
  line-height: 32px;
}

.container__switch {
  position: relative;
  display: flex;
  width: 312px;
  padding: 14px 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 8px;
  border: 1px solid rgba(51, 143, 235, 0.2);
  background: #fff;
}

.table_Heading_Container {
  height: 70px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 1px solid #e5f1ff;
}

.search_form {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.table_toolbar {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.table__header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background: white;
  border-bottom: 1px solid var(--primary-50, #e5f1ff);
}

.table__header__leftcontent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 23px;
}

.table__header__searchbar {
  display: flex;
  width: 400px;
  height: 48px;
  padding: 14px 8px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #dfe1e6;
  background: #fafbfc;

  color: var(--neutral-light-n-100, #7a869a);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
}

.table__header__search--btnSecondary {
  display: flex;
  width: 112px;
  height: 48px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  outline: none;
  border-radius: 8px;
  background: var(--neutral-light-alpha-n-20-a, rgba(9, 30, 66, 0.04));
}

.table__header__rightcontent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 23px;
}

.table__header__checkusage--btnLink {
  display: flex;
  height: 48px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  color: #0052cc;
}

.table__header__additem--btnPrimary {
  display: flex;
  width: 160px;
  height: 48px;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  color: #fff;
  background: var(--blue-b-400, #0052cc);
}

.table__body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  font-size: 14px;
  border-bottom: 1px solid var(--primary-50, #e5f1ff);
}

.table__body__heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  padding: 20px 30px;
  width: 100%;
  font-weight: 500;
}

.table__body__serialnumber {
  min-width: 15%;
}

.table__body__name {
  min-width: 20%;
}

.table__body__itemcode {
  min-width: 15%;
}

.table__body__price {
  min-width: 15%;
}

.table__body__quantity {
  min-width: 10%;
}

.table__body__actions {
  min-width: 150px;
}

.table__body__name {
  min-width: 200px;
}

.table__body__itemcode {
  min-width: 150px;
}

.table__body__price {
  min-width: 150px;
}

.table__body__quantity {
  min-width: 100px;
}

.table__body__actions {
  min-width: 300px;
}

.table__body__item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  width: 100%;
  border-bottom: 1px solid var(--primary-50, #e5f1ff);
}

.table__body__item .table__body__actions {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.btnDanger {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  background: rgba(255, 46, 31, 0.1);
  color: rgba(255, 46, 31, 1);
}

.btnMain {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  background: rgba(0, 95, 204, 0.1);
  color: rgba(0, 95, 204, 1);
}

.btnCaution {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  background: rgba(255, 212, 0, 0.1);
  color: #ff8c00;
}

.btn:hover {
  cursor: pointer;
  opacity: 0.8;
}
